.datatable-crud .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.status-ACTIVE {
  background: #c8e6c9;
  color: #256029;
}

.status-INACTIVE {
  background: #e6e6e6;
  color: #585858;
}

.status-READY_FOR_PUBLISH {
  background: #c8e6c9;
  color: #256029;
}

.status-EXPIRED {
  background: #ffcdd2;
  color: #c63737;
}

.status-PUBLISHED {
  background-color: #eccfff;
  color: #694382;
}

.status-IN_PROGRESS {
  background-color: #b3e5fc;
  color: #23547b;
}

.status-RAW_CONTENT {
  background-color: #ffd8b2;
  color: #805b36;
}

.status-WAITING_FOR_MATERIALS {
  background-color: #feedaf;
  color: #8a5340;
}

.status-DELETED {
  background-color: #76d9e08c;
  color: #999999;
}

.status-ENDED {
  background-color: #fcd446;
  color: #8a5340;
}
.status-WAITING_PAYMENT {
  background-color: #e992eaa6;
  color: #555454;
}

@media screen and (max-width: 960px) {
  .datatable-crud .table-header {
    align-items: flex-start;
  }
}
.datatable-crud .p-dialog {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.datatable-crud .confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  .datatable-crud .p-toolbar {
    flex-wrap: wrap;
  }
  .datatable-crud .p-toolbar .p-button {
    margin-bottom: 0.25rem;
  }
  .datatable-crud .table-header {
    flex-direction: column;
  }
  .datatable-crud .table-header .p-input-icon-left,
  .datatable-crud .table-header input {
    width: 100%;
  }
}

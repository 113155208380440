$primary-bg-color: #FFFF;
$submenu-bg-color: #ffffff;
$submenu-border-color: #ffffff;
$hover-color: #ffffff;
$font-color: #020202;

.layout-menu {
  background-color: $primary-bg-color;
  padding: 20px 0;
  color: $font-color;

  .layout-menuitem {
    transition: background-color 0.3s; 
    .submenu-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      cursor: pointer;
      font-weight: bold;
    }

    .submenu-icon {
      font-size: 20px;
    }
  }

  .submenu {
    background-color: $submenu-bg-color;

    li {
      border-bottom: 1px solid $submenu-border-color;

      a {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        color: $font-color;
        transition: background-color 0.3s;

        &:hover {
          background-color: $hover-color;
        }

        i {
          margin-right: 10px;
          font-size: 20px;
        }
      }
    }
  }
}

.layout-submenu-wrapper-enter {
  opacity: 0.01;
  transform: translateY(-10px);
}

.layout-submenu-wrapper-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms, transform 200ms;
}

.layout-submenu-wrapper-exit {
  opacity: 1;
  transform: translateY(0);
}

.layout-submenu-wrapper-exit-active {
  opacity: 0.01;
  transform: translateY(-10px);
  transition: opacity 200ms, transform 200ms;
}
